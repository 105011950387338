"use client"
import { fetchFAQsByPage } from "./service";
import Faq from "./component/Faq";
import { useEffect, useState } from "react";
import { usePathname } from 'next/navigation';

const FaqSection = ({style, classname}) => {
    const [data, setData] = useState(null);
    const path = usePathname();

    useEffect(() => {
        if (!path) return;
        const fetchFAQs = async () => {
            const faqs = await fetchFAQsByPage({ path });
            setData(faqs);
        };

        fetchFAQs();
    }, [path]);
    
    if (!path || !data || !data.faq) return null;

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": data.faq.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answerNoFormatting
            }
        }))
    };

    return (
        <>
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
            {
                data.faq && (<Faq data={data.faq} style={style} classname={classname} />)
            }
        </>
    )
};

export default FaqSection;